import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    background: {
      default: "#FFFFFF",
    },
    primary: {
      main: "#2b2e6e",
    },
  },
  typography: {
    fontSize: 11,
  },
  overrides: {
    MuiToolbar: {
      regular: {
        height: "48px",
        minHeight: "48px",
        "@media(min-width:600px)": {
          minHeight: "48px",
        },
      },
    },
    MUIDataTable: {
      paper: {
        boxShadow: "none",
      },
    },
  },
});

export default theme;
