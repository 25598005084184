import uuid from "uuid-js";

/**
 * Converts an arrayBuffer to Base64
 *
 * @param bytes
 *
 * @returns {string} The Base64 representation of the buffer
 */
function toBase64(bytes) {
  let binary = "";
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

/**
 * Converts an Base64 encoded string to Uint8Array
 *
 * @param base64
 *
 * @returns {string} The buffer representation of the base64 encoded string
 */
function fromBase64(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes;
}

/**
 * Converts an Uint8Array to url safe Base64
 *
 * @param buffer
 *
 * @returns {string} The Base64 representation of the buffer
 */
function toUrlSafeBase64(buffer) {
  return toBase64(buffer).replace(/\//g, "_").replace(/\+/g, "-");
}

/**
 * Converts an url safe Base64 encoded string to Uint8Array
 *
 * @param urlSafeBase64
 *
 * @returns {string} The buffer representation of the base64 encoded string
 */
function fromUrlSafeBase64(urlSafeBase64) {
  return fromBase64(urlSafeBase64.replace(/_/g, "/").replace(/-/g, "+"));
}

/**
 * bytes to uuid converter
 *
 * @param {Uint8Array} val The 128 bits representing a UUID
 *
 * @returns {uuid} Returns uuid
 */
function bytesToUuid(val) {
  return uuid.fromBytes(val).toString();
}

const converterService = {
  toBase64: toBase64,
  fromBase64: fromBase64,
  toUrlSafeBase64: toUrlSafeBase64,
  fromUrlSafeBase64: fromUrlSafeBase64,
  bytesToUuid: bytesToUuid,
};

export default converterService;
