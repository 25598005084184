import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import BlockIcon from "@material-ui/icons/Block";
import CheckIcon from "@material-ui/icons/Check";

import logo from "../assets/img/logo.svg";
import appleLogo from "../assets/img/apple-store-badge.svg";
import googleLogo from "../assets/img/google-play-badge.svg";

import Copyright from "../components/copyright";
import apiServer from "../services/api-server";
import deviceService from "../services/device";
import converter from "../services/converter";
import GridContainerErrors from "../components/grid-container-errors";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
      borderWidth: "1px",
      borderColor: "#DADCE0FF",
      borderStyle: "solid",
      borderRadius: "8px",
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    margin: theme.spacing(1),
    maxWidth: "200px",
  },
  appleLogo: {
    margin: theme.spacing(1),
    maxWidth: "200px",
  },
  googleLogo: {
    margin: theme.spacing(1),
    maxWidth: "200px",
  },
}));

function AuthenticationRequestView(props) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  React.useEffect(() => {
    setTimeout(function () {
      if (deviceService.isMobileIos()) {
        // ios
        document.location =
          "https://apps.apple.com/us/app/quant-authenticator/id6459475820";
      } else if (deviceService.isMobileAndroid()) {
        // Android
        document.location =
          "https://play.google.com/store/apps/details?id=com.quantauth.quantauth";
      }
    }, 300);
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} alt="logo" className={classes.logo} />
        <Typography
          component="h1"
          variant="h5"
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          {t("REDIRECT")}
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} className={classes.details}>
            <Alert variant="outlined" severity="warning">
              {t("REDIRECT_IN_3_CLICK_LINK_BELOW_IF_NOT_WORKING")}
            </Alert>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            style={{
              marginTop: "5px",
              marginBottom: "5px",
              paddingRight: "5px",
            }}
          >
            <a
              href={
                "https://play.google.com/store/apps/details?id=com.quantauth.quantauth"
              }
            >
              <img
                src={googleLogo}
                alt="googleLogo"
                className={classes.googleLogo}
              />
            </a>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            style={{
              marginTop: "5px",
              marginBottom: "5px",
              paddingLeft: "5px",
            }}
          >
            <a
              href={
                "https://apps.apple.com/us/app/quant-authenticator/id6459475820"
              }
            >
              <img
                src={appleLogo}
                alt="appleLogo"
                className={classes.appleLogo}
              />
            </a>
          </Grid>
        </Grid>
      </div>
      <Box mt={2}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default AuthenticationRequestView;
