import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import BlockIcon from "@material-ui/icons/Block";
import CheckIcon from "@material-ui/icons/Check";

import logo from "../assets/img/logo.svg";

import Copyright from "../components/copyright";
import apiServer from "../services/api-server";
import deviceService from "../services/device";
import converter from "../services/converter";
import GridContainerErrors from "../components/grid-container-errors";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
      borderWidth: "1px",
      borderColor: "#DADCE0FF",
      borderStyle: "solid",
      borderRadius: "8px",
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  logo: {
    margin: theme.spacing(1),
    maxWidth: "200px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function AuthenticationRequestView(props) {
  const { t, i18n } = useTranslation();
  const [applicationTitle, setApplicationTitle] = useState("");
  const [userIp, setUserIp] = useState("");
  const [userName, setUserName] = useState("");
  const [userOrigin, setUserOrigin] = useState("");
  const [validTill, setValidTill] = useState(null);
  const [errors, setErrors] = useState([]);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const classes = useStyles();
  const params = useParams();

  const loadAuthenticationRequestData = () => {
    setLoading(true);
    apiServer
      .readAuthenticationRequest(
        converter.bytesToUuid(
          converter.fromUrlSafeBase64(params.authenticationRequestId)
        )
      )
      .then(
        (result) => {
          setApplicationTitle(result.data.application_title);
          setUserIp(result.data.user_ip);
          setUserName(result.data.user_name);
          setUserOrigin(result.data.user_origin);
          setValidTill(result.data.valid_till);
          i18n.changeLanguage(result.data.language);
          setLoading(false);
        },
        (result) => {
          console.log(result.data);
          if (result.data.hasOwnProperty("authentication_request_id")) {
            setErrors(result.data["authentication_request_id"]);
          } else if (result.data.hasOwnProperty("non_field_errors")) {
            setErrors(result.data["non_field_errors"]);
          }
          setLoading(false);
        }
      );
  };

  React.useEffect(() => {
    if (deviceService.isMobileAndroid() || deviceService.isMobileIos()) {
      document.location = `com.quantauth.quantauth:${window.location.href.substring(
        window.location.protocol.length
      )}`;
      setTimeout(function () {
        if (confirm(t("DOWNLOAD_AND_INSTALL_QUANTAUTH_NOW"))) {
          if (deviceService.isMobileIos()) {
            // ios
            document.location =
              "https://apps.apple.com/us/app/quant-authenticator/id6459475820";
          } else {
            // Android
            document.location =
              "https://play.google.com/store/apps/details?id=com.quantauth.quantauth";
          }
        } else {
          loadAuthenticationRequestData();
        }
      }, 300);
    } else {
      loadAuthenticationRequestData();
    }
  }, []);

  const approve = () => {
    setLoading(true);
    apiServer
      .updateAuthenticationRequest(
        converter.bytesToUuid(
          converter.fromUrlSafeBase64(params.authenticationRequestId)
        ),
        params.authenticationRequestApprovalSecret,
        "confirmed"
      )
      .then(
        (result) => {
          setMessages(["AUTHENTICATION_REQUEST_APPROVED_SUCCESSFULLY"]);
          setLoading(false);
          setSuccess(true);
        },
        (result) => {
          console.log(result.data);
          if (result.data.hasOwnProperty("authentication_request_id")) {
            setErrors(result.data["authentication_request_id"]);
          } else if (result.data.hasOwnProperty("non_field_errors")) {
            setErrors(result.data["non_field_errors"]);
          }
          setLoading(false);
        }
      );
  };
  const deny = () => {
    apiServer
      .updateAuthenticationRequest(
        converter.bytesToUuid(
          converter.fromUrlSafeBase64(params.authenticationRequestId)
        ),
        params.authenticationRequestApprovalSecret,
        "confirmed"
      )
      .then(
        (result) => {
          setMessages(["AUTHENTICATION_REQUEST_DENIED_SUCCESSFULLY"]);
          setLoading(false);
        },
        (result) => {
          console.log(result.data);
          if (result.data.hasOwnProperty("authentication_request_id")) {
            setErrors(result.data["authentication_request_id"]);
          } else if (result.data.hasOwnProperty("non_field_errors")) {
            setErrors(result.data["non_field_errors"]);
          }
          setLoading(false);
        }
      );
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} alt="logo" className={classes.logo} />
        <Typography
          component="h1"
          variant="h5"
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          {t("AUTHENTICATION")}
        </Typography>
        <Grid container>
          <GridContainerErrors errors={errors} />
          <GridContainerErrors errors={messages} severity={"success"} />

          {!loading && !success && errors.length === 0 && (
            <>
              <Grid item xs={12} sm={12} md={12} className={classes.details}>
                <Alert variant="outlined" severity="warning">
                  {t("APPROVE_OR_DENY_AUTHENTICATION_REQUEST")}
                </Alert>
                <Typography
                  component="h1"
                  variant="h6"
                  style={{ marginTop: "20px", marginBottom: "10px" }}
                >
                  {t("DETAILS")}
                </Typography>
                <Typography component="p">{applicationTitle}</Typography>
                {Boolean(userName) && (
                  <Typography component="p">{userName}</Typography>
                )}
                {Boolean(userIp) && (
                  <Typography component="p">
                    {t("IP")}: {userIp}
                  </Typography>
                )}
                {Boolean(userOrigin) && (
                  <Typography component="p">
                    {t("ORIGIN")}: {userOrigin}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                style={{
                  marginTop: "5px",
                  marginBottom: "5px",
                  paddingRight: "5px",
                }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: "#4d9818", color: "#FFFFFF" }}
                  className={classes.submit}
                  onClick={approve}
                  size="large"
                  startIcon={<CheckIcon />}
                >
                  {t("APPROVE")}
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                style={{
                  marginTop: "5px",
                  marginBottom: "5px",
                  paddingLeft: "5px",
                }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  style={{ backgroundColor: "#98182b", color: "#FFFFFF" }}
                  onClick={deny}
                  size="large"
                  className={classes.submit}
                  startIcon={<BlockIcon />}
                >
                  <span>{t("DENY")}</span>
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </div>
      <Box mt={2}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default AuthenticationRequestView;
