import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

function Copyright(props) {
  const { t } = useTranslation();
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link color="inherit" href="https://quantauth.com/terms-and-conditions/">
        {t("TERMS_AND_CONDITIONS")}
      </Link>{" "}
      <Link color="inherit" href="https://quantauth.com/legal-notice/">
        {t("LEGAL_NOTICE")}
      </Link>{" "}
      <Link color="inherit" href="https://quantauth.com/privacy-policy/">
        {t("PRIVACY_POLICY")}
      </Link>
      <br />
      &copy;{" "}
      <Link color="inherit" href="https://esaqa.com/">
        esaqa GmbH
      </Link>{" "}
    </Typography>
  );
}

export default Copyright;
