import React, { Suspense } from "react";

// initially required for stripe
import "core-js/stable";
import "regenerator-runtime/runtime";

import { render } from "react-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { I18nextProvider } from "react-i18next";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import i18n from "./i18n";
import theme from "./theme";
import IndexView from "./views/index";
import AuthenticationRequestView from "./views/authentication-request";
import AppView from "./views/app";

const customHistory = createBrowserHistory();

function App(props) {
  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <Router history={customHistory}>
          <div>
            <Switch>
              <Route path="/a/:authenticationRequestId/:authenticationRequestApprovalSecret/">
                <AuthenticationRequestView />
              </Route>
              <Route path="/app/">
                <AppView />
              </Route>
              <Route path="/">
                <IndexView />
              </Route>
            </Switch>
          </div>
        </Router>
      </ThemeProvider>
    </I18nextProvider>
  );
}

export default App;

const container = document.getElementById("app");
render(
  <Suspense fallback="loading...">
    <App />
  </Suspense>,
  container
);
