import axios from "axios";

function getServerUrlPrefix() {
  return "/api/v1/frontendapi";
}

/**
 * Helper function that handled the actual requests and encrypts them and decrypts the result (if applicable)
 *
 * @param {function} method
 * @param {string} endpoint
 * @param {object} data
 * @param {object} headers
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function call(method, endpoint, data, headers) {
  const url = getServerUrlPrefix() + endpoint;

  headers["X-Requested-With"] = "XMLHttpRequest";

  return new Promise((resolve, reject) => {
    axios({
      method,
      url,
      data,
      headers,
    })
      .then((data) => {
        resolve(data);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
          return reject(error.response);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        reject({ errors: ["SERVER_OFFLINE"] });
      });
  });
}

/**
 * GET: Returns the details of an authentication request
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function readAuthenticationRequest(authenticationRequestId) {
  const endpoint = "/authentication-request/" + authenticationRequestId + "/";
  const connectionType = "GET";
  const data = null;
  const headers = {};

  return call(connectionType, endpoint, data, headers);
}

/**
 * POST: Updates an authentication request
 *
 * @param {string} authenticationRequestId
 * @param {string} authenticationRequestApprovalSecret
 * @param {string} authenticationRequestStatus
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function updateAuthenticationRequest(
  authenticationRequestId,
  authenticationRequestApprovalSecret,
  authenticationRequestStatus
) {
  const endpoint = "/authentication-request/";
  const connectionType = "PUT";
  const data = {
    authentication_request_id: authenticationRequestId,
    authentication_request_approval_secret: authenticationRequestApprovalSecret,
    authentication_request_status: authenticationRequestStatus,
  };
  const headers = {};

  return call(connectionType, endpoint, data, headers);
}

const service = {
  readAuthenticationRequest,
  updateAuthenticationRequest,
};

export default service;
