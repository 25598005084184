import React from "react";

function IndexView(props) {
  React.useEffect(() => {
    window.location.href = "https://quantauth.com";
  }, []);

  return null;
}

export default IndexView;
